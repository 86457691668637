<template>
  <div class="bank_card">
    <main>
      <p class="none" v-if="!bankCard.length">暂无银行卡</p>
      <div class="bank_card_list" v-else>
        <div
          class="card"
          v-for="list in bankCard"
          :key="list.bankAcctNo"
          :style="[
            {
              backgroundImage: getBankBg(list.bankCode2),
            },
          ]"
        >
          <img :src="getBankLogo(list.bankCode2)" alt="" class="logo" />
          <div class="content">
            <p class="card_name">{{ list.bankName + list.bankCode2 }}</p>
            <p class="card_type">{{ list.cardTypeName }}</p>
            <p class="card_num">{{ list.bankAcctNo | cardNumber }}</p>
          </div>
          <div class="controls" v-if="bankCard.length > 1">
            <svg-icon icon-class="del" />
            <span @click="deleteBankCard(list)">删除银行卡</span>
          </div>
          <div v-else style="width: 0.88rem"></div>
        </div>
      </div>
      <van-button round type="info" color="#009A4D" block @click="add">
        添加银行卡
      </van-button>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Dialog } from "vant";
export default {
  name: "bank-card",
  computed: {
    ...mapState("user", ["isLogin", "bankCard", "userInfo"]),
  },
  data() {
    return {};
  },
  filters: {
    cardNumber(val) {
      let num = val.substr(0, 4) + "********" + val.substr(15);
      return num;
    },
  },

  async created() {
    await this.$store.dispatch("user/queryElectronicAcct");
    // let a = [];
    // this.bankCard.forEach((e, i) => {
    //   let obj = {};
    //   for (const key in e) {
    //     this.$set(obj, key, e[key]);
    //   }

    //   this.$set(a, i, e);
    // });
    // console.log(a);
  },

  methods: {
    async add() {
      this.Toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      await this.$API.user
        .queryApplications({
          object: {
            serviceType: 1,
            userId: this.userInfo.id,
          },
        })
        .then((res) => {
          this.Toast.clear();
          if (res.data.data.status) {
            this.$router.push("addBankCard");
          } else {
            Dialog.confirm({
              message: "需先开通资金账户\n是否去开通资金账户",
            })
              .then(() => {
                // on confirm
                this.$router.push("/promotion/promotionRegistration");
              })
              .catch(() => {
                // on cancel
              });
          }
        })
        .catch((e) => {
          console.log(e);
          this.Toast.clear();
        });
    },
    deleteBankCard(val) {
      let num =
        val.bankAcctNo.substr(0, 4) + "********" + val.bankAcctNo.substr(15);
      Dialog.confirm({
        message: `确定要删除卡号：${num}？`,
      })
        .then(async () => {
          // on confirm
          await this.$API.user
            .delSettleAcct({
              object: {
                serviceType: "1",
                settleAcctId: val.settleAcctId,
              },
            })
            .then((res) => {
              if (res.data.code != "SUCCESS") throw Error(res.data.msg);
              this.$store.dispatch("user/selectSettleAcctList");
              this.Toast.success("删除成功");
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    getBankBg(code) {
      let img = "qt";
      switch (code) {
        case "COMM":
          img = "COMM";
          break;
        case "ABC":
          img = "ABC";
          break;
        case "CCB":
          img = "CCB";
          break;
        case "CITIC":
          img = "CITIC";
          break;
        case "CMB":
          img = "CMB";
          break;
        case "GCB":
          img = "GCB";
          break;
        case "ICBC":
          img = "ICBC";
          break;
        case "PSBC":
          img = "PSBC";
          break;
        case "SPABANK":
          img = "SPABANK";
          break;
        case "SPDB":
          img = "SPDB";
          break;
        default:
          break;
      }
      return `url(${require(`@/assets/images/bank/${img}_bg.png`)})`;
    },
    getBankLogo(code) {
      let img = "qt";
      switch (code) {
        case "COMM":
          img = "COMM";
          break;
        case "ABC":
          img = "ABC";
          break;
        case "CCB":
          img = "CCB";
          break;
        case "CITIC":
          img = "CITIC";
          break;
        case "CMB":
          img = "CMB";
          break;
        case "GCB":
          img = "GCB";
          break;
        case "ICBC":
          img = "ICBC";
          break;
        case "PSBC":
          img = "PSBC";
          break;
        case "SPABANK":
          img = "SPABANK";
          break;
        case "SPDB":
          img = "SPDB";
          break;
        default:
          break;
      }
      return `${require(`@/assets/images/bank/${img}_logo.png`)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.bank_card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 46px;
  main {
    flex: 1;
    background: #f5f5f5;
    padding: 15px;
    .none {
      color: #999999;
      text-align: center;
      padding: 44px 0;
    }
    .bank_card_list {
      .card {
        border-radius: 12px;
        height: 135px;
        display: flex;
        justify-content: space-between;
        color: #fff;
        padding: 16px 12px;
        margin-bottom: 12px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .logo {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background: #fff;
        }
        .content {
          .card_name {
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 8px;
          }
          .card_type {
            font-size: 12px;
            line-height: 17px;
            margin-bottom: 15px;
          }
          .card_num {
            line-height: 20px;
          }
        }
        .controls {
          display: flex;
          align-items: center;
          .svg-icon {
            color: #fff;
            margin-right: 4px;
          }
        }
      }
      .card:last-child {
        margin-bottom: 0;
      }
    }
    .van-button {
      margin-top: 65px;
    }
  }
}
</style>
